body {
  background-color: black;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  /* Set the height to 100% of the viewport height */
}

.logo {
  height: 40vh;
  width: 100%;
}

.parent-div {
  margin-left: 40px;
  margin-right: 40px;
}

.blurb {
  text-align: 'left';
}

/* For screens wider than 768px */
@media (min-width: 768px) {
  .parent-div {
    margin-left: 300px;
    margin-right: 300px;
  }
}

.Three {
  height: 100%;
  width: 100%;
}

.CollapsibleTextInputPanel {
  display: flex;
  align-items: flex-start;
}

.CollapsibleTextInputBtn {
  border: none;
  background-color: #000000;
  color: #ff0000
}


.CollapsibleTextInput {
  width: 95%;
  height: 200px;
  font-size: 1.0em;
  border: 1px solid #2b2b2b;
  outline: none;
  background: transparent;
  color: #ff0000;
  text-align: left;
}

.red {
  color: #ff0000;
}

.TextInput2 {
  font-size: 1.0em;
  border: 1px solid #2b2b2b;
  outline: none;
  background: transparent;
  color: #ff0000;
  text-align: left;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.TextInputPanel {
  width: 85%;
  height: 100%;
}


.OffscreenTextInput {
  width: 100%;
  height: 200px;
  font-size: 1.0em;
  border: 1px solid #2b2b2b;
  outline: none;
  background: transparent;
  color: #ff0000;
  text-align: left;
}

.social-icon {
  filter: hue-rotate(60deg);
  margin: 2px;
}

.footer {
  position: fixed;
  bottom: 0;
  margin-left: 40px;
}